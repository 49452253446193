import axios from "axios";

import {
  FETCH_RIDES_REQUEST,
  FETCH_RIDES_SUCCESS,
  FETCH_RIDES_FAILURE,
  FETCH_SINGLE_RIDE_REQUEST,
  FETCH_SINGLE_RIDE_SUCCESS,
  FETCH_SINGLE_RIDE_FAILURE,
  UPDATE_SINGLE_RIDE_REQUEST,
  UPDATE_SINGLE_RIDE_SUCCESS,
  UPDATE_SINGLE_RIDE_FAILURE,
  CANCEL_RIDE_REQUEST,
  CANCEL_RIDE,
  RESEND_INVOICE,
  RESEND_INVOICE_REQUEST,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_REQUEST,
  FETCH_PAYMENTS_FAILURE,
  ADD_PAYMENT_FAILURE,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_REQUEST,
  ODOMETER_READING_FAILURE,
  ODOMETER_READING_REQUEST,
  ODOMETER_READING_SUCCESS,
  STATE_CLEAN,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAILURE,
} from "./RidesTypes";

import {
  fetchRidesURL,
  fetchSingleRideURL,
  updateSingleRideURL,
  cancelRideURL,
  resendInvoiceURL,
  fetchPaymentsURL,
  recordPaymentURL,
  odometerStartURL,
  endRideFromPortalURL,
  updateEndOdometerURL,
  addDriverURL,
  zoomSyncRefundToVendor,
} from "../../urls";

// Fetch Rides
export const fetchRidesRequest = () => {
  return {
    type: FETCH_RIDES_REQUEST,
  };
};

export const fetchRidesSuccess = (Rides) => {
  return {
    type: FETCH_RIDES_SUCCESS,
    payload: Rides,
  };
};

export const fetchRidesFailure = (error) => {
  return {
    type: FETCH_RIDES_FAILURE,
    payload: error,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchRides = () => {
  var dataSource = {
    bidStatusId: 100140002,
    orderSource: "RETAIL",
  };
  return (dispatch) => {
    dispatch(fetchRidesRequest());
    axios
      .post(fetchRidesURL, dataSource)
      .then((response) => {
        // const Rides = response.data.responseData;
        // console.log(response.data);
        const Rides = {
          rides: response.data.responseData,
          ridesFlag: "New Bookings",
        };
        dispatch(fetchRidesSuccess(Rides));
      })
      .catch((error) => {
        dispatch(fetchRidesFailure(error.message));
      });
  };
};

export const fetchRidesFilter = (bidStatusID) => {
  // console.log("bid status ID ", bidStatusID);
  var Headers = { headers: { "Content-Type": "application/json" } };
  var dataSource;
  const statusDict = {
    Requested: [100140001],
    "New Bookings": [100140002],
    Ongoing: [100140005, 100110002],
    Confirmed: [100140005, 100110007],
    "Partial Cancel": [100140012],
    Cancelled: [100140007],
    Completed: [100140005, 100110004],
    "Place Holder": [100140013, 100110008],
  };
  statusDict[bidStatusID].length === 2
    ? (dataSource = JSON.stringify({
        bidStatusId: statusDict[bidStatusID][0],
        orderSource: "RETAIL",
        rideStatusId: statusDict[bidStatusID][1],
      }))
    : (dataSource = JSON.stringify({
        bidStatusId: statusDict[bidStatusID][0],
        orderSource: "RETAIL",
      }));
  return (dispatch) => {
    console.log(bidStatusID);
    dispatch(fetchRidesRequest());
    axios
      .post(fetchRidesURL, dataSource, Headers)
      .then((response) => {
        const Rides = {
          rides: response.data.responseData,
          ridesFlag: bidStatusID,
        };
        // console.log(response.data);
        dispatch(fetchRidesSuccess(Rides));
      })
      .catch((error) => {
        dispatch(fetchRidesFailure(error.message));
      });
  };
};

export const fetchRidesbyMobileNo = (mobile) => {
  var dataSource = {
    mobileNo: mobile,
  };
  return (dispatch) => {
    dispatch(fetchRidesRequest());
    axios
      .post(fetchRidesURL, dataSource)
      .then((response) => {
        const Rides = {
          rides: response.data.responseData,
          ridesFlag: "All Rides",
        };
        // console.log(response.data);
        dispatch(fetchRidesSuccess(Rides));
      })
      .catch((response) => {
        // console.log(response);
        dispatch(fetchRidesFailure(response.statusMSG));
      });
  };
};

// Single Ride
export const fetchSingleRideRequest = () => {
  return {
    type: FETCH_SINGLE_RIDE_REQUEST,
  };
};

export const fetchSingleRideSuccess = (singleRide) => {
  return {
    type: FETCH_SINGLE_RIDE_SUCCESS,
    payload: singleRide,
  };
};
export const fetchSingleRideFailure = (error) => {
  return {
    type: FETCH_SINGLE_RIDE_FAILURE,
    payload: error,
  };
};

export const fetchSingleRide = (RideData) => {
  // console.log(RideData);
  const data = {
    bidId: RideData.state.data.id,
  };
  return (dispatch) => {
    dispatch(fetchSingleRideRequest());
    // console.log("update Agency data", RideData, flag)
    axios
      .post(fetchSingleRideURL, data)
      .then((response) => {
        // console.log(response.data.responseData[0]);
        dispatch(fetchSingleRideSuccess(response.data.responseData[0]));
        //   dispatch(fetchRidesRequest())
      })
      .catch((error) => {
        // console.log(response)
        dispatch(fetchSingleRideFailure(error.message));
      });
  };
};

// Single Ride Update

export const updateSingleRideRequest = () => {
  return {
    type: UPDATE_SINGLE_RIDE_REQUEST,
  };
};
export const updateSingleRideSuccess = (statusMSG) => {
  return {
    type: UPDATE_SINGLE_RIDE_SUCCESS,
    payload: statusMSG,
  };
};
export const updateSingleRideFailure = (error) => {
  return {
    type: UPDATE_SINGLE_RIDE_FAILURE,
    payload: error,
  };
};

export const updateSingleRide = (RideData) => {
  return (dispatch) => {
    dispatch(updateSingleRideRequest());
    // console.log("update Agency data", RideData);
    axios
      .post(updateSingleRideURL, RideData)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(updateSingleRideSuccess(response.data.statusMSG));
        //   dispatch(fetchRidesRequest())
      })
      .catch((error) => {
        // console.log(response)
        dispatch(updateSingleRideFailure(error.message));
      });
  };
};

export const odometerReadingRequest = () => {
  return {
    type: ODOMETER_READING_REQUEST,
  };
};

export const odometerReadingSuccess = (statusMSG) => {
  return {
    type: ODOMETER_READING_SUCCESS,
    payload: statusMSG,
  };
};
export const odometerReadingFailure = (error) => {
  return {
    type: ODOMETER_READING_FAILURE,
    payload: error,
  };
};

export const odometerReading = ({ odometerData, odoType }) => {
  var odometerReadingURL =
    odoType === "start"
      ? odometerStartURL
      : odoType === "updateOdo"
      ? updateEndOdometerURL
      : endRideFromPortalURL;
  return (dispatch) => {
    dispatch(odometerReadingRequest());
    axios
      .post(odometerReadingURL, odometerData)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(odometerReadingSuccess(response.data.statusMSG));
        //   dispatch(fetchRidesRequest())
      })
      .catch((response) => {
        // console.log(response)
        dispatch(odometerReadingFailure(response.statusMSG));
      });
  };
};

export const cancelRideRequest = () => {
  return {
    type: CANCEL_RIDE_REQUEST,
  };
};

export const cancelRideMSG = (statusMSG) => {
  return {
    type: CANCEL_RIDE,
    payload: statusMSG,
  };
};

export const cancelRide = (RideData) => {
  return (dispatch) => {
    dispatch(cancelRideRequest());
    axios
      .post(cancelRideURL, RideData)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(cancelRideMSG(response.data.statusMSG));
      })
      .catch((error) => {
        // console.log(response)
        dispatch(cancelRideMSG(error.message));
      });
  };
};

export const resendInvoiceRequest = () => {
  return {
    type: RESEND_INVOICE_REQUEST,
  };
};
export const resendInvoiceMSG = (statusMSG) => {
  return {
    type: RESEND_INVOICE,
    payload: statusMSG,
  };
};

export const resendInvoice = (RideData) => {
  var Headers = { headers: { "Content-Type": "application/json" } };
  return (dispatch) => {
    dispatch(resendInvoiceRequest());
    axios
      .post(resendInvoiceURL, { bidId: RideData }, Headers)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(resendInvoiceMSG(response.data.statusMSG));
      })
      .catch((error) => {
        // console.log(response)
        dispatch(resendInvoiceMSG(error.message));
      });
  };
};

export const fetchPaymentsRequest = () => {
  return {
    type: FETCH_PAYMENTS_REQUEST,
  };
};
export const fetchPaymentsSuccess = (payments) => {
  return {
    type: FETCH_PAYMENTS_SUCCESS,
    payload: payments,
  };
};
export const fetchPaymentsFailure = (errorMsg) => {
  return {
    type: FETCH_PAYMENTS_FAILURE,
    payload: errorMsg,
  };
};

export const fetchPayments = (bidID) => {
  // console.log("fetchPayments", bidID);
  return (dispatch) => {
    // console.log("dispatch");
    dispatch(fetchPaymentsRequest());
    axios
      .post(fetchPaymentsURL, { type: "Bid", ride: { bidId: bidID } })
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(fetchPaymentsSuccess(response.data.responseData));
      })
      .catch((error) => {
        // console.log(response)
        dispatch(fetchPaymentsFailure(error.message));
      });
  };
};

export const addPaymentRequest = () => {
  return {
    type: ADD_PAYMENT_REQUEST,
  };
};
export const addPaymentSuccess = (message) => {
  return {
    type: ADD_PAYMENT_SUCCESS,
    payload: message,
  };
};
export const addPaymentFailure = (errorMsg) => {
  return {
    type: ADD_PAYMENT_FAILURE,
    payload: errorMsg,
  };
};

export const addPayment = (paymentDetails, accountId) => {
  return (dispatch) => {
    dispatch(addPaymentRequest());
    axios
      .post(recordPaymentURL, paymentDetails)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(addPaymentSuccess(response.data.statusMSG));
        if (accountId === 85) {
          // axios.post(zoomSyncRefundToVendor, {})
        }
      })
      .catch((error) => {
        // console.log(response)
        dispatch(addPaymentFailure(error.message));
      });
  };
};

//

export const addDriverRequest = () => {
  return {
    type: ADD_DRIVER_REQUEST,
  };
};
export const addDriverSuccess = (message) => {
  return {
    type: ADD_DRIVER_SUCCESS,
    payload: message,
  };
};
export const addDriverFailure = (errorMsg) => {
  return {
    type: ADD_DRIVER_FAILURE,
    payload: errorMsg,
  };
};

export const addDriver = (paymentDetails) => {
  return (dispatch) => {
    dispatch(addDriverRequest());
    axios
      .post(addDriverURL, paymentDetails)
      .then((response) => {
        // console.log(response.data.statusMSG);
        dispatch(addDriverSuccess(response.data.statusMSG));
      })
      .catch((error) => {
        // console.log(response)
        dispatch(addDriverFailure(error.message));
      });
  };
};

//
export const stateClean = () => {
  return {
    type: STATE_CLEAN,
  };
};

// assign driver and vehicle

// /updateVehicleDriverDetails
// MIME Type: application/x-www-form-urlencoded; charset=UTF-8
// bid_order_id: 9883291
// driver_name: Zyppys Test
// driver_mobile: 9898756745
// driver_email: test@zypys.com
// submitDetails: Submit

// assign Vehicle

// /updateRideFinalDetails
// name: FinalVehicleRegNo
// value: TS00CP0000
// pk: 1
// bidId: 9883291

// Check assigned driver

// /checkAssignedDriver
// ride_id: 12481

// Live Api Details

// /getLiveDetailsFromApi
// ride_id: 12526
