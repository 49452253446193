import React from "react";
import { Link } from "gatsby";
import { MenuSection, MenuLinks, MenuItem } from "../style/menu";

const ActiveStyle = {
  borderRight: "1rem white solid",
  boxShadow: "0 1px 0.5rem rgba(0, 20, 40, 0.1)",
};
export const Menu = () => {
  return (
    <MenuSection>
      <MenuLinks>
        <Link activeStyle={ActiveStyle} to="/rides">
          <MenuItem>Rides</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/quotes">
          <MenuItem>Quotes</MenuItem>
        </Link>
        {/* <Link activeStyle={ActiveStyle} to="/agencies">
          <MenuItem>Travel Partners</MenuItem>
        </Link> */}
        <Link activeStyle={ActiveStyle} to="/skippedorders">
          <MenuItem>Skipped Orders</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/vendorpayouts">
          <MenuItem>Vendor Payouts</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/refundreport">
          <MenuItem>S-D Refund Report</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/metrics">
          <MenuItem>Metrics</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/vehicleavailability">
          <MenuItem>Vehicle Avail.</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/pricing">
          <MenuItem>Product Pricing</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/addorupdatevendor">
          <MenuItem>Zyppys Vendor</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/swingusers">
          <MenuItem>Swing Users</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/swingtrips">
          <MenuItem>Swing Trips</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/zyppysexplore">
          <MenuItem>Zyppys Explore</MenuItem>
        </Link>
        <Link activeStyle={ActiveStyle} to="/notifications">
          <MenuItem>Notifications</MenuItem>
        </Link>
      </MenuLinks>
    </MenuSection>
  );
};
