import axios from "axios";

import {
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_VENDOR_PAYOUTS_FAILURE,
  FETCH_VENDOR_PAYOUTS_REQUEST,
  FETCH_VENDOR_PAYOUTS_SUCCESS,
  FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST,
  FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS,
  FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE,
  FETCH_METRICS_REQUEST,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILURE,
  STATE_CLEAN,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  UPDATE_SUB_CATEGORIES,
  UPDATE_CATEGORIES,
} from "./VendorsTypes";

import {
  fetchVendorBidsURL,
  fetchBuisnessesURL,
  fetchSelfDriveRefundReportURL,
  fetchMetricsURL,
  fetchLocationsURL,
  addOrUpdateVendorURL,
  fetchLookupURL,
} from "../../urls";

// Businesses/ Vendors
export const fetchBusinessesRequest = () => {
  return {
    type: FETCH_BUSINESSES_REQUEST,
  };
};

export const fetchBusinessesSuccess = (Businesses) => {
  return {
    type: FETCH_BUSINESSES_SUCCESS,
    payload: Businesses,
  };
};

export const fetchBusinessesFailure = (error) => {
  return {
    type: FETCH_BUSINESSES_FAILURE,
    payload: error,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchBusinesses = () => {
  return (dispatch) => {
    dispatch(fetchBusinessesRequest());
    axios
      .post(fetchBuisnessesURL)
      .then((response) => {
        // const Businesses = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchBusinessesSuccess(response.data.responseData));
      })
      .catch((error) => {
        dispatch(fetchBusinessesFailure(error.message));
      });
  };
};
// Locations
export const fetchLocationsRequest = () => {
  return {
    type: FETCH_LOCATIONS_REQUEST,
  };
};

export const fetchLocationsSuccess = (Locations) => {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    payload: Locations,
  };
};

export const fetchLocationsFailure = (error) => {
  return {
    type: FETCH_LOCATIONS_FAILURE,
    payload: error,
  };
};

export const fetchLocations = () => {
  return (dispatch) => {
    dispatch(fetchLocationsRequest());

    axios
      .post(fetchLocationsURL)
      .then((response) => {
        // const Businesses = response.data.responseData;
        // console.log(response.data.responseData);
        dispatch(fetchLocationsSuccess(response.data.responseData));
      })
      .catch((error) => {
        dispatch(fetchLocationsFailure(error.message));
      });
  };
};

export const fetchVendorPayoutsRequest = (vendor) => {
  return {
    type: FETCH_VENDOR_PAYOUTS_REQUEST,
    payload: vendor,
  };
};

export const fetchVendorPayoutsSuccess = (Payouts) => {
  return {
    type: FETCH_VENDOR_PAYOUTS_SUCCESS,
    payload: Payouts,
  };
};

export const fetchVendorPayoutsFailure = (error) => {
  return {
    type: FETCH_VENDOR_PAYOUTS_FAILURE,
    payload: error,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchVendorPayouts = (data) => {
  // console.log(data);
  // var vendorPayBody;
  // if (data.vendor === "All" || data.data.accountId === null) {
  //   vendorPayBody = {
  //     startDate: data.data.startDate,
  //     endDate: data.data.endDate,
  //   };
  // } else {
  //   vendorPayBody = data.data;
  // }
  // console.log(vendorPayBody);
  return (dispatch) => {
    dispatch(fetchVendorPayoutsRequest(data.vendor));
    axios
      .post(fetchVendorBidsURL, data.data)
      .then((response) => {
        // const Businesses = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchVendorPayoutsSuccess(response.data.responseData));
      })
      .catch((error) => {
        dispatch(fetchVendorPayoutsFailure(error.message));
      });
  };
};

export const fetchSelfDriveRefundReportRequest = () => {
  return {
    type: FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST,
  };
};

export const fetchSelfDriveRefundReportSuccess = (refundreport) => {
  return {
    type: FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS,
    payload: refundreport,
  };
};

export const fetchSelfDriveRefundReportFailure = (error) => {
  return {
    type: FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE,
    payload: error,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchSelfDriveRefundReport = (data) => {
  // console.log(data);
  return (dispatch) => {
    dispatch(fetchSelfDriveRefundReportRequest());
    axios
      .post(fetchSelfDriveRefundReportURL, data)
      .then((response) => {
        // const Businesses = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchSelfDriveRefundReportSuccess(response.data.responseData));
      })
      .catch((error) => {
        console.log(error.message);
        // dispatch(fetchSelfDriveRefundReportFailure(error.message));
      });
  };
};

export const fetchMetricsRequest = () => {
  return {
    type: FETCH_METRICS_REQUEST,
  };
};

export const fetchMetricsSuccess = (metrics) => {
  return {
    type: FETCH_METRICS_SUCCESS,
    payload: metrics,
  };
};

export const fetchMetricsFailure = (error) => {
  return {
    type: FETCH_METRICS_FAILURE,
    payload: error,
  };
};

export const updateSubCategories = (data) => {
  return {
    type: UPDATE_SUB_CATEGORIES,
    payload: data,
  };
};
export const updateCategories = (data) => {
  return {
    type: UPDATE_CATEGORIES,
    payload: data,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchMetrics = (data) => {
  // console.log(data);
  return (dispatch) => {
    dispatch(fetchMetricsRequest());
    axios
      .post(fetchMetricsURL, data)
      .then((response) => {
        // const Businesses = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchMetricsSuccess(response.data.responseData));
      })
      .catch((error) => {
        dispatch(fetchMetricsFailure(error.message));
      });
  };
};

// Insert or Update Business/ Vendor

export function addOrUpdateVendor(data) {
  return (dispatch) => {
    // let url = urls.insertOrUpdateBusiness;
    axios
      .post(addOrUpdateVendorURL, data)
      .then((res) => {
        if (res.status == 200) {
          // console.log(res);
          alert(res.data.statusMSG);
          // success(data,status);
        }
      })
      .catch((err) => {
        // console.log(err);
        alert(err);
      });
  };
}

//
export const stateClean = () => {
  return {
    type: STATE_CLEAN,
  };
};

export const fetchSubCat = () => {
  return (dispatch) => {
    console.log("Fetching sub categories");
    axios({
      method: "post",
      url: fetchLookupURL,
      data: "10016",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(async (response) => {
        console.log("Fetch sub cates Res", response.data.responseData);
        if (response.data.responseData !== null && response.data.responseData.length > 0) {
          let resArray = response.data.responseData;
          resArray.forEach(function (element) {
            element.value = element.id;
            element.label = element.lookupDescription
          });
          dispatch(updateSubCategories(resArray));
        }
      })
      .catch((err) => {
        console.log("Fetch sub cates Err", err);
      });
  };
};
export const fetchCategories = () => {
  return (dispatch) => {
    console.log("Fetching sub categories");
    axios({
      method: "post",
      url: fetchLookupURL,
      data: "10009",
      headers: {
        "content-type": "application/json",
      },
    })
      .then(async (response) => {
        console.log("Fetch categories Res", response.data.responseData);
        if (response.data.responseData !== null && response.data.responseData.length > 0) {
          let resArray = response.data.responseData;
          resArray.forEach(function (element) {
            element.value = element.id;
            element.label = element.lookupDescription
          });
          console.log("Category Array after manipulation", resArray);
          dispatch(updateCategories(resArray));
        }
      })
      .catch((err) => {
        console.log("Fetch cates Err", err);
      });
  };
};