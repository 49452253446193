import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_ACTION,
} from "./AuthTypes";
import { userLoginURL } from "../../urls";
import axios from "axios";
export const loginRequest = () => {
  return {
    type: USER_LOGIN_REQUEST,
  };
};

export const loginSuccess = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  return {
    type: USER_LOGIN_SUCCESS,
    payload: user,
  };
};
export const loginFailure = (errorMsg) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: errorMsg,
  };
};

export const logoutAction = () => {
  localStorage.setItem("user", JSON.stringify({}));
  return {
    type: USER_LOGOUT_ACTION,
  };
};

export const userLogin = (loginData) => {
  return (dispatch) => {
    dispatch(loginRequest);
    axios
      .post(userLoginURL, loginData)
      .then((response) => {
        console.log("response", response);
        if (response.data.statusCD === 200 && response.data.responseData !== null) {
          const user = response.data.responseData;
          // console.log("login data from response", user)
          dispatch(loginSuccess(user));
        }
        else {
          dispatch(loginFailure(response.data.statusMSG));
        }
      })
      .catch((error, response) => {
        // console.log("login error", error, response)
        dispatch(loginFailure(error.message));
      });
  };
};
