import styled from "styled-components";

export const NavBarSection = styled.header`
  z-index: 10;
  grid-area: navbar;
  height: 4rem;
  width: 100%;
  padding: 0;
  display: flex;
  top: 0;
  position: sticky;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 1px 0.5rem rgba(0, 20, 40, 0.1);
  font-family: "roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  img {
    height: 2rem;
  }
  @media (max-width: 414px) {
    height: fit-content;
    width: 100%;
    img {
      height: 1.7rem;
    }
    background-color: #aa0bca;
  }
`;
export const LogoSection = styled.div`
  ${tw``};
  background-color: #aa0bca;
  height: 100%;
  width: 12rem;
  display: flex;
  padding: 1rem 0 1rem 1.7rem;
  @media (max-width: 414px) {
    margin: 1rem 1rem;
    padding: 0;
    height: 3rem;
    width: fit-content;
    background-color: none;
    height: fit-content;

    p {
      /* display: none; */
      font-size: 1.2rem;
      /* padding-left: 0.5rem; */
    }
    a {
      height: rem;
    }
  }
`;

export const NavLinks = styled.div`
  margin: auto 40px;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  h4 {
    margin: auto 0;
  }
  button {
    padding: 0.7rem 1.4rem;
    border: none;
    border-radius:20px;
    color: white;
    background-color: red;
    cursor: pointer;
    font-size: 0.8rem;
    :hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 414px) {
    padding: auto 0;
    width: auto;
    height: 3rem;
    h4 {
      color: white;
      font-size: 0.8rem;
      font-weight: normal;
    }
    button {
      margin: auto 1rem;
      color: white;
      height: 2rem;
      font-size: 0.7rem;
      font-weight: normal;
      padding: 0.2rem 0.8rem;
    }
  }
`;
export const Text = styled.p`
  ${tw` text-white `};
  margin: auto 0;
  padding-left: 0.5rem;
  font-size: 1.2rem;
  font-weight: lighter;
`;
