// if (process.env.NODE_ENV !== "production") {
//UAT
// var apiURL = "https://uat.zyppys.in/";
// var apiSwingURL = "https://swinguat.zyppys.in/dashboard/";
// var apiAppSwingURL = "https://swinguat.zyppys.in/";
// var userId = 7799;
// } else {
var userId = 71989;
var apiURL = "https://api.zyppys.in/";
var apiSwingURL = "https://swingprod.zyppys.in/dashboard/";
var apiAppSwingURL = "https://swingprod.zyppys.in/";
// }

export const adminUserId = userId;

// const api = "https://uat.zyppys.in/";
const api = "https://doctoriteprod.zyppys.in/";

// Agencies
export const fetchAgenciesURL = apiURL + "fetchPartners";
export const updateAgencyStatusURL = apiURL + "insertOrUpdatePartner";
export const fetchSelfDriveVehiclesURL = apiURL + "fetchSelfDriveVehicles";
export const updateVehicleAvailabilityURL =
  apiURL + "updateVehicleAvailability";
export const fetchProductPricingURL = apiURL + "fetchProductPricing";
export const insertOrUpdateProductPricingURL =
  apiURL + "insertOrUpdateProductPricing";


// Auth
export const userLoginURL = api + "loginPortal";

// Quote

// Vendors

export const addOrUpdateVendorURL = apiURL + "insertOrUpdateBusiness";
export const fetchLookupURL = apiURL + "fetchLookupByCategory";

// Rides
export const fetchRidesURL = apiURL + "fetchBids";
export const fetchSingleRideURL = apiURL + "fetchBidDetails";
export const updateSingleRideURL = apiURL + "updateRideAttribute";
export const odometerStartURL = apiURL + "startRide";
export const endRideFromPortalURL = apiURL + "endRideFromPortal";
export const fetchCustomerProfileURL = apiURL + "fetchProfile";
export const updateEndOdometerURL = apiURL + "updateEndOdometer";
export const cancelRideURL = apiURL + "cancelRide";
export const checkAssignedDriverURL = apiURL + "checkAssignedDriver";
export const resendInvoiceURL = apiURL + "resendInvoice";
export const fetchPaymentsURL = apiURL + "fetchPayments";
export const recordPaymentURL = apiURL + "recordPayment";
export const addDriverURL = apiURL + "updateRideFinalDetails";
//zoom rides
export const zoomGetRefundAmount = apiURL + "getRefundAmount";
export const zoomSyncRefundToVendor = apiURL + "syncRefundToVendor";

// Reports
export const fetchBuisnessesURL = apiURL + "fetchBusinesses";
export const fetchLocationsURL = apiURL + "fetchLocations";
export const fetchVendorBidsURL = apiURL + "fetchVendorBids";
export const fetchSelfDriveRefundReportURL =
  // apiURL +
  "https://api.zyppys.in/fetchSelfDriveRefundReport";
export const fetchMetricsURL = apiURL + "fetchMetrics";

// Skipped Orders
export const fetchSkippedOrdersURL = apiURL + "fetchSkippedOrders";

// Zyppys Explore

export const fetchExplorePostsURL = apiURL + "fetchVideos";
export const updateExplorePostURL = apiURL + "insertOrUpdateVideo";
export const fetchVideoCategoriesURL = apiURL + "fetchLookupByCategory";

// *** SWING URLS *** //

export const fetchSwingUsersURL = apiSwingURL + "users?pageNumber=1&pageLimit=1000";
export const fetchSwingTripsURL = apiSwingURL + "trips?pageNumber=1&pageLimit=1000";
export const swingUserURL = (userId) => apiAppSwingURL + "users/" + userId;
export const swingVehicleURL = (vehicleId) =>apiAppSwingURL + "vehicles/" + vehicleId;
export const createVehicle = apiAppSwingURL + 'vehicles'
export const vehicleCategoires = apiAppSwingURL + 'vehicleCategories'
export const location = apiAppSwingURL + 'locations'
export const vehicleModels = apiAppSwingURL + 'vehicleModels'
export const vehicleType = apiAppSwingURL + 'vehicleTypes'
export const swingQuotesURL = (tripId) => apiAppSwingURL + `trips/${tripId}/quotes`;


//   //
//   {"documents": [
//     {
//         "userId": 764,
//         "documentType": 1,
//         "documentTypeName": "profilePic",
//         "documentUri": "https://swing-live.s3.amazonaws.com/960bfb0b21ab885d1f712fcf0ed779dc/profilePic.jpg",
//         "fileName": "rn_image_picker_lib_temp_695baa61-0eeb-4efa-827d-1700549b0a10.jpg"
//     }
// ]}
//   //

//   //
//   {"documents": [
//     {
//         "userId": 437,
//         "documentType": 1,
//         "documentTypeName": "profilePic",
//         "documentUri": "https://swing-uat.s3.amazonaws.com/user437/profilePic.png",
//         "fileName": "unnamed.png"
//     },]}
//     //
