import axios from "axios";

import {
  FETCH_SKIPPEDORDERS_REQUEST,
  FETCH_SKIPPEDORDERS_SUCCESS,
  FETCH_SKIPPEDORDERS_FAILURE,
  // FETCH_SINGLE_RIDE_SUCCESS,
  // FETCH_SINGLE_RIDE_FAILURE,
  // UPDATE_SINGLE_RIDE_SUCCESS,
  // UPDATE_SINGLE_RIDE_FAILURE,
} from "./SkippedOTypes";

import { fetchSkippedOrdersURL } from "../../urls";

// Fetch skippedOrders
export const fetchSkippedOrdersRequest = () => {
  return {
    type: FETCH_SKIPPEDORDERS_REQUEST,
  };
};

export const fetchSkippedOrdersSuccess = (skippedOrders) => {
  return {
    type: FETCH_SKIPPEDORDERS_SUCCESS,
    payload: skippedOrders,
  };
};

export const fetchSkippedOrdersFailure = (error) => {
  return {
    type: FETCH_SKIPPEDORDERS_FAILURE,
    payload: error,
  };
};

// Current date
// var today = new Date();
// var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

export const fetchSkippedOrders = () => {
  var dataSource = {};
  return (dispatch) => {
    dispatch(fetchSkippedOrdersRequest());
    axios
      .post(fetchSkippedOrdersURL, dataSource)
      .then((response) => {
        const skippedOrders = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchSkippedOrdersSuccess(skippedOrders));
      })
      .catch((error) => {
        dispatch(fetchSkippedOrdersFailure(error.message));
      });
  };
};

export const fetchSkippedOrdersFilter = (skippedOrdersFilter) => {
  // console.log("bid status ID ", bidStatusID);
  // var Headers = { headers: { "Content-Type": "application/json" } };
  // var dataSource = ({
  //   "startDate" : "29-09-2020 13:35:48",
  //   "endDate" : "01-10-2020 13:35:48"
  // });
  return (dispatch) => {
    // console.log(bidStatusID);
    dispatch(fetchSkippedOrdersRequest());
    axios
      .post(fetchSkippedOrdersURL, skippedOrdersFilter)
      .then((response) => {
        const skippedOrders = response.data.responseData;
        // console.log(response.data);
        dispatch(fetchSkippedOrdersSuccess(skippedOrders));
      })
      .catch((error) => {
        dispatch(fetchSkippedOrdersFailure(error.message));
      });
  };
};
