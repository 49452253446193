import styled from "styled-components";

export const MenuSection = styled.section`
  grid-area: menu;
  position: sticky;
  min-height: calc(100vh - 4rem);
  width: 12rem;
  padding: 0;
  justify-content: space-between;
  background-color: #aa0bca;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @media (max-width: 414px) {
    min-height: fit-content;
    width: 100%;
    padding: 0.3rem;
  }
`;

export const MenuLinks = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  a {
    margin-bottom: 1.5rem;
    @media (max-width: 414px) {
      height: fit-content;
      width: 50%;
    }
  }
  @media (max-width: 414px) {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem;
  }
`;
export const MenuItem = styled.p`
  ${tw` text-white `};
  width: 12rem;
  padding: 1.5rem 0.7rem 1.5rem 1.7rem;
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 1px;
  :hover {
    border-right: 0.7rem white solid;
    box-shadow: 0 1px 0.5rem rgba(0, 20, 40, 0.1);
  }
  @media (max-width: 414px) {
    :hover {
      border-right: none;
      border-bottom: 0.2rem solid white;
    }
    .ActiveStyle {
      border-right: none;
      border-bottom: 0.5rem solid white;
    }
    height: fit-content;
    width: 10rem;
    font-size: 0.7rem;
    padding: 0.5rem 0.4rem 0.5rem 0.4rem;
    /* width: 50%; */
    /* margin-right: 0; */
  }
`;
