import axios from "axios";

import {
  FETCH_AGENCIES_REQUEST,
  FETCH_AGENCIES_SUCCESS,
  FETCH_AGENCIES_FAILURE,
  UPDATE_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_REQUEST,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  UPDATE_VEHICLE_AVAILABILITY__SUCCESS,
  UPDATE_VEHICLE_AVAILABILITY__FAILURE,
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE,
  ADD_UPDATE_PRICING_REQUEST,
  ADD_UPDATE_PRICING_SUCCESS,
  ADD_UPDATE_PRICING_FAILURE,
  STATE_CLEAN,
} from "./AgenciesTypes";

import {
  fetchAgenciesURL,
  updateAgencyStatusURL,
  fetchSelfDriveVehiclesURL,
  updateVehicleAvailabilityURL,
  fetchProductPricingURL,
  insertOrUpdateProductPricingURL,
} from "../../urls";
import { FETCH_PAYMENTS_FAILURE } from "../rides/RidesTypes";

// Fetch Agencies
export const fetchAgenciesRequest = () => {
  return {
    type: FETCH_AGENCIES_REQUEST,
  };
};

export const fetchAgenciesSuccess = (Agencies) => {
  return {
    type: FETCH_AGENCIES_SUCCESS,
    payload: Agencies,
  };
};

export const fetchAgenciesFailure = (error) => {
  return {
    type: FETCH_AGENCIES_FAILURE,
    payload: error,
  };
};

export const fetchAgencies = () => {
  var dataSource = {};
  return (dispatch) => {
    dispatch(fetchAgenciesRequest());
    axios
      .post(fetchAgenciesURL, dataSource)
      .then((response) => {
        const Agencies = response.data.responseData;
        // console.log(response);
        dispatch(fetchAgenciesSuccess(Agencies));
      })
      .catch((error) => {
        dispatch(fetchAgenciesFailure(error.message));
      });
  };
};

// Agency status update

export const updateAgencySuccess = (statusMsg) => {
  return {
    type: UPDATE_AGENCY_SUCCESS,
    payload: statusMsg,
  };
};
export const updateAgencyFailure = (error) => {
  return {
    type: UPDATE_AGENCY_FAILURE,
    payload: error,
  };
};

export const updateAgency = (AgencyData, flag) => {
  return (dispatch) => {
    // console.log("update Agency data", AgencyData, flag)
    if (flag === "statusUpdate") {
      axios
        .post(updateAgencyStatusURL, AgencyData)
        .then((response) => {
          // console.log(response)
          dispatch(updateAgencySuccess(response.data.statusMSG));
          //   dispatch(fetchAgenciesRequest())
        })
        .catch((error, response) => {
          // console.log(response)
          dispatch(updateAgencyFailure(error.message));
        });
    }
  };
};

// Fetch Vehicle Availability
export const fetchVehicleAvailabilityRequest = () => {
  return {
    type: FETCH_VEHICLE_AVAILABILITY_REQUEST,
  };
};

export const fetchVehicleAvailabilitySuccess = (VehicleAvailability) => {
  return {
    type: FETCH_VEHICLE_AVAILABILITY_SUCCESS,
    payload: VehicleAvailability,
  };
};

export const fetchVehicleAvailabilityFailure = (error) => {
  return {
    type: FETCH_VEHICLE_AVAILABILITY_FAILURE,
    payload: error,
  };
};

export const fetchVehicleAvailability = (dataInput) => {
  // var dataSource = {};
  // console.log(dataInput);

  return (dispatch) => {
    // console.log(dataInput, dispatch);

    dispatch(fetchVehicleAvailabilityRequest());

    axios
      .post(fetchSelfDriveVehiclesURL, dataInput)
      .then((response) => {
        const VehicleAvailability = response.data.responseData;
        // console.log(response);
        dispatch(fetchVehicleAvailabilitySuccess(VehicleAvailability));
      })
      .catch((error) => {
        dispatch(fetchVehicleAvailabilityFailure(error.message));
      });
  };
};

export const updateVehicleAvailabilitySuccess = (statusMsg) => {
  return {
    type: UPDATE_VEHICLE_AVAILABILITY__SUCCESS,
    payload: statusMsg,
  };
};
export const updateVehicleAvailabilityFailure = (error) => {
  return {
    type: UPDATE_VEHICLE_AVAILABILITY__FAILURE,
    payload: error,
  };
};

export const updateVehicleAvailability = (data) => {
  return (dispatch) => {
    axios
      .post(updateVehicleAvailabilityURL, data)
      .then((response) => {
        dispatch(updateVehicleAvailabilitySuccess(response.data.statusMSG));
        alert(response.data.statusMSG);
        return;
      })
      .catch((error) => {
        dispatch(updateVehicleAvailabilityFailure(error.message));
      });
  };
};

// Fetch Pricing
export const fetchPricingRequest = () => {
  return {
    type: FETCH_PRICING_REQUEST,
  };
};

export const fetchPricingSuccess = (Pricing) => {
  return {
    type: FETCH_PRICING_SUCCESS,
    payload: Pricing,
  };
};

export const fetchPricingFailure = (error) => {
  return {
    type: FETCH_PRICING_FAILURE,
    payload: error,
  };
};

export const fetchPricing = (dataSource) => {
  // var dataSource = {};
  return (dispatch) => {
    dispatch(fetchPricingRequest());
    axios
      .post(fetchProductPricingURL, dataSource)
      .then((response) => {
        const Pricing = response.data.responseData;
        // console.log(response);
        dispatch(fetchPricingSuccess(Pricing));
      })
      .catch((error) => {
        alert(error.message);
        dispatch(fetchPricingFailure(error.message));
      });
  };
};

// Add/ Update Pricing
export const fetchAddUpdatePricingRequest = () => {
  return {
    type: ADD_UPDATE_PRICING_REQUEST,
  };
};

// export const fetchAddUpdatePricingSuccess = (message) => {
//   return {
//     type: ADD_UPDATE_PRICING_SUCCESS,
//     payload: message,
//   };
// };

export const fetchAddUpdatePricingFailure = (error) => {
  return {
    type: ADD_UPDATE_PRICING_FAILURE,
    payload: error,
  };
};

export const addOrUpdatePricing = (dataSource) => {
  // var dataSource = {};
  return (dispatch) => {
    axios
      .post(insertOrUpdateProductPricingURL, dataSource)
      .then((response) => {
        const Pricing = response.data.statusMSG;
        alert(Pricing);
        // dispatch(fetchAddUpdatePricingRequest());
        // console.log(response);
        // dispatch(fetchAddUpdatePricingSuccess(Pricing));
      })
      .catch((error) => {
        alert(error.message);
        // dispatch(fetchAddUpdatePricingFailure(error.message));
      });
  };
};

//
export const stateClean = () => {
  return {
    type: STATE_CLEAN,
  };
};
