import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import { NavBarSection, LogoSection, NavLinks, Text } from "../style/navbar";
import { logoutAction } from "../state";
export const NavBar = () => {
  const DoctoriteLogo = useStaticQuery(graphql`
    query {
      doctoritelogo: file(relativePath: { eq: "doctoritelogo.png" }) {
        publicURL
      }
    }
  `);
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);
  // console.log("user from navbar", loginData.portalUserId, loginBool);

  const handleLogout = () => {
    dispatch(logoutAction());
    localStorage.setItem("user", JSON.stringify({}));
    navigate("/login");
  };
  return (
    <NavBarSection>
      <LogoSection>
        <Link to="/">
          {/* <img
            src={DoctoriteLogo.doctoritelogo.publicURL}
            alt="Logo of Doctorite"
          /> */}
        </Link>
        <Text>Zyppys</Text>
      </LogoSection>
      <NavLinks>
        {loginBool === true ? (
          <>
            <h4>
              {loginData.firstName} {loginData.lastName}
            </h4>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          " "
        )}
      </NavLinks>
    </NavBarSection>
  );
};
